.review-parent {
  color: white;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: center;
  /* border: 1px solid white; */
}

.review-container-left-right {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1350px;
  /* border: 1px solid red; */
}

.review-container {
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  width: 868px;
  margin-right: 10px;
  gap: 5px;
}

.review-header {
  text-align: left;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 1000;
  /* text-shadow: 4px 1px 0px rgb(102, 192, 244, 0.4); */
  line-height: 35px;
  /* border-bottom: 1px solid rgb(68, 89, 121, 0.5); */
  cursor: default;
  user-select: none;
}

.individual-review-container {
  display: flex;
  flex-direction: row;
  /* width: 865px; */
  margin-left: 1px;
  margin-bottom: 20px;
  /* padding: 3px; */
  /* border: 1px solid rgb(68, 89, 121, 0.5); */
  /* border-top: 1px solid rgb(68, 89, 121, 0.5); */
  /* background-color: rgba(26, 38, 51, 0.6); */
  background-color: linear-gradient(
    0deg,
    transparent 0%,
    rgba(36, 40, 49, 1) 100%
  );
}

.individual-review-container2 {
  display: flex;
  flex-direction: row;
  margin-left: 1px;
  margin-bottom: 20px;
  /* background-color: rgb(68, 89, 121, 0.2); */
  background: linear-gradient(
    145deg,
    transparent 20%,
    rgba(68, 89, 121, 0.2) 100%
  );
  /* border: 1px solid rgba(45, 230, 255, 0.3); */
  /* box-shadow: 0px 0px 0px 1px rgba(45, 230, 255, 0.3); */
  padding: 10px;
  border: 1px solid;
  border-image-slice: 1;
  border-image: linear-gradient(
      var(--angle),
      rgb(45, 230, 255, 0.8),
      rgb(45, 125, 255, 0.5)
    )
    1;
  animation: 5s rotate linear infinite;
  --angle: 0deg;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

.author-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  padding: 0px 5px 3px 0px;
  /* border-right: 1px solid rgb(68, 89, 121, 0.5); */
  /* width: 100%; */
}

.author-avatar img {
  user-select: none;
  height: 68px;
  width: 78px;
  object-fit: cover;
  /* outline: 1px solid rgb(68, 89, 121, 0.5); */
  border: 1px solid #445979;
  /* border-image: linear-gradient(to top, #445979, transparent) 1; */
}

.review-text-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* margin-left: 5px; */
  /* padding: 3px; */
  /* padding-bottom: 20px; */
  /* background-color: rgb(27, 40, 56, 0.8); */
  /* border: 1px solid white; */
}

.review-text-top-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* padding: 3px; */
  /* border: 1px solid rgb(68, 89, 121, 0.5); */
}

.review-top-info-container {
  padding: 5px 4px 5px 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: linear-gradient(
    -145deg,
    transparent 20%,
    rgba(122, 195, 238, 0.1) 100%
  );
  border-top: 1px solid rgb(68, 89, 121, 0.5);
  border-bottom: 1px solid rgb(68, 89, 121, 0.5);
  font-size: 12px;
}

.review-posted {
  color: rgb(150, 150, 150);
  font-size: 10px;
  text-transform: uppercase;
}

.recommended-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: linear-gradient(
    145deg,
    transparent 0%,
    rgba(9, 11, 15, 0.5) 100%
  );
  border-bottom: 1px solid rgb(68, 89, 121, 0.5);
}

.recommended,
.not-recommended {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.thumbs-up,
.thumbs-down {
  font-size: 24px;
  padding: 2px 8px;
  /* border: 1px solid pink; */
  margin-right: 10px;
}

.thumbs-up {
  background: linear-gradient(
    0deg,
    rgb(12, 53, 80, 0.3) 0%,
    rgb(63, 103, 129, 0.6) 100%
  );
}

.thumbs-down {
  background: linear-gradient(0deg, rgb(77, 22, 28) 0%, rgb(119, 72, 78) 100%);
}

.thumb-icon {
  opacity: 0.6;
  text-shadow: 2px 3px 0px rgba(0, 0, 0, 0.6);
}

.key-icon {
  margin-right: 10px;
}

.review-text {
  text-align: justify;
  font-size: 12px;
  padding: 5px 0px;
}

.review-user-actions {
  /* border-top: 1px solid rgb(255, 255, 255, 0.2); */
  /* background-color: black; */
  /* border-top: 1px solid;
  border-image: linear-gradient(to right, transparent, rgb(255, 255, 255, 0.1))
    1; */
  font-size: 11px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.review-edit-btns-container {
  margin-top: 5px;
}

.review-user-actions-edit {
  margin-top: 15px;
  border-top: 1px solid rgb(255, 255, 255, 0.1);
  font-size: 11px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.edit-review,
.delete-review,
.cancel-review,
.save-review {
  margin: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  transition: 0.4s;
}

.edit-review,
.cancel-review {
  cursor: pointer;
  background-color: rgb(255, 255, 255, 0.4);
}

.edit-review:hover,
.cancel-review:hover {
  background-color: rgb(255, 255, 255);
  color: black;
}

.delete-review {
  background-color: rgb(255, 0, 0, 0.4);
  margin-left: 5px;
}

.delete-review:hover {
  background-color: rgb(255, 0, 0);
}

.save-review {
  cursor: pointer;
  background-color: rgb(62, 110, 139);
}

.save-review:hover {
  background-color: rgb(37, 145, 212);
}

.edit-review,
.delete-review {
  cursor: pointer;
}

.review-right-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  position: sticky;
  position: -webkit-sticky; /* for browser compatibility */
  top: 35%;
  width: 465px;
  /* height: 300px; */
  height: 100%;
  margin: 40px 0px;
  border: 1px solid rgb(68, 89, 121, 0.5);
  background: linear-gradient(-45deg, transparent 0%, rgba(36, 40, 49, 1) 100%);
  text-align: justify;
}

.review-create-header {
  font-size: 15px;
  color: rgb(255, 255, 255, 0.9);
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.9);
  padding: 5px 10px;
  background: linear-gradient(
    90deg,
    rgba(64, 100, 100, 0.8) 0%,
    rgb(20, 41, 41, 0.3) 100%
  );
}

.review-create-top-container {
  /* margin: 5px 0px; */
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.review-create-user-avatar {
  margin-right: 10px;
}

.review-create-user-avatar img {
  border: 1px solid #445979;
  height: 60px;
  width: 70px;
  object-fit: cover;
}

.review-create-subtext-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.review-create-subtext {
  /* padding: 10px 10px 0px 10px; */
  color: rgb(102, 192, 244);
  font-size: 14px;
}

.review-create-subtext2 {
  /* padding: 10px; */
  font-size: 12px;
}

.review-create-input-container,
.review-edit-input-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.review-create-input {
  /* border: 1px solid red; */
  /* width: 437px; */
  padding: 10px 16px 10px 10px;
  display: flex;
  flex-direction: column;
}

.review-edit-input {
  display: flex;
  flex-direction: column;
  margin: 5px 0px 10px 0px;
  width: 100%;
}

.review-create-input > textarea {
  border: 1px solid rgb(68, 89, 121, 0.8);
  background-color: rgb(0, 0, 0, 0.15);
  color: white;
  font-size: 13px;
  text-align: justify;
  resize: none;
  /* background-color: transparent; */
  height: 150px;
  width: 423px;
  padding: 10px;
}

.review-edit-input > textarea {
  border: 1px solid rgb(68, 89, 121, 0.8);
  background-color: rgb(0, 0, 0, 0.25);
  color: white;
  font-size: 12px;
  text-align: justify;
  resize: none;
  /* background-color: transparent; */
  height: 100px;
  /* width: 423px; */
  padding: 10px;
}

.do-you-rec {
  border: none;
  /* height: 100%; */
}

.do-you-rec-edit {
  border: none;
  padding: 0;
}

.do-you-rec-1 {
  font-size: 14px;
}

.do-you-rec-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.do-you-rec-radio-container input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.do-you-rec-radio-container label {
  background-color: rgb(255, 255, 255, 0.1);
  color: white;
  font-size: 12px;
  text-align: center;
  padding: 5px 16px;
  /* border: 1px solid rgba(255, 255, 255, 0.2); */
  /* box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1); */
  /* transition: all 0.2s ease-in-out; */
}

.do-you-rec-radio-container label:hover {
  cursor: pointer;
}

.do-you-rec-radio-container input:checked + label:first-of-type {
  outline: 1px solid rgb(255, 255, 255, 0.05);
  background: linear-gradient(
    0deg,
    rgb(12, 53, 80, 0.3) 10%,
    rgb(63, 103, 129, 0.8) 100%
  );
  box-shadow: none;
}

.do-you-rec-radio-container input:checked + label:last-of-type {
  outline: 1px solid rgb(255, 255, 255, 0.05);
  background: linear-gradient(0deg, rgb(77, 22, 28) 10%, rgb(119, 72, 78) 100%);
  box-shadow: none;
}

.do-you-rec-radio-container label:first-of-type {
  margin-right: 3px;
  border-radius: 4px;
}

.do-you-rec-radio-container label:last-of-type {
  border-radius: 4px;
}

.post-review-container button {
  cursor: pointer;
  font-size: 14px;
  background-color: white;
  color: black;
  padding: 8px 12px;
  border-radius: 5px;
}

.purchase-me-container {
  user-select: none;
  opacity: 0.5;
  font-size: 12px;
  text-transform: uppercase;
  cursor: default;
  position: relative;
  border: 1px solid rgb(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 465px;
  height: 38px;
}

.purchase-me-container.arrow-top,
.purchase-me-container.arrow-left {
  margin-top: 40px;
}

.purchase-me-container.arrow-top:after {
  content: " ";
  position: absolute;
  /* right: 30px; */
  top: -11px;
  border-top: none;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid rgb(255, 255, 255, 0.5);
}

.purchase-me-container.arrow-left:after {
  content: " ";
  position: absolute;
  left: -11px;
  /* top: 15px; */
  border-top: 10px solid transparent;
  border-right: 10px solid rgb(255, 255, 255, 0.5);
  border-left: none;
  border-bottom: 10px solid transparent;
}
