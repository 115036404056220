.entire-library-parent {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.entire-library-container {
  display: flex;
  flex-direction: row;
  color: white;
  height: 100%;
}
.library-sidebar-parent {
  height: 100%;
  margin-top: 98px;
  width: 320px;
  border-right: 1px solid rgb(255, 255, 255, 0.4);
  background-color: rgb(255, 255, 255, 0.05);
}

.library-sidebar-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.my-games-header {
  font-size: 14px;
  padding: 5px;
  text-transform: uppercase;
}

.library-game-list-container {
  font-size: 12px;
  cursor: pointer;
  padding: 5px 5px 5px 20px;
  /* background-color: rgb(255, 255, 255, 0.1); */
  transition: 0.4s;
}

.library-game-list-container:hover {
  background-color: rgb(255, 255, 255, 0.2);
}

.library-parent {
  /* height: 100%; */
  width: 100%;
  margin-left: 20px;
  margin-top: 110px;
  display: flex;
  flex-direction: column;
}

.library-header {
  font-size: 46px;
  font-weight: bold;
  text-transform: uppercase;
}

.library-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.library-game-container {
  padding: 5px;
  border: 1px solid rgb(68, 89, 121, 0.5);
  display: flex;
  flex-direction: column;
  width: 380px;
  transition: all 0.3s ease-in-out;
  z-index: 999;
  background: linear-gradient(
    145deg,
    transparent 20%,
    rgba(36, 40, 49, 0.5) 100%
  );
  /* height: 265px; */
  /* margin: 5px; */
}

.library-game-container:hover {
  background-color: rgb(36, 40, 49, 0.5);
  /* background: linear-gradient(
    145deg,
    transparent 20%,
    rgba(36, 40, 49, 1) 100%
  ); */
  transform: scale(1.03);
  border: 1px solid rgb(120, 146, 185, 0.8);
}

.library-banner {
}

.library-info-container {
  /* margin: 5px; */
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.library-game-title-container {
  /* padding: 5px; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  margin-bottom: 15px;
}

.library-game-title {
  font-size: 20px;
  /* font-weight: bold; */
}

.library-game-purchase {
  font-size: 12px;
  text-transform: uppercase;
  color: rgb(150, 150, 150);
}

.library-links-container {
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
}

.library-button {
  cursor: pointer;
  text-transform: uppercase;
  border: 1px solid rgb(255, 255, 255, 0.4);
  width: 100%;
  padding: 3px;
  background-color: rgb(255, 255, 255, 0.05);
  /* border-radius: 3px; */
  font-size: 12px;
  transition: 0.2s;
}

.library-button:hover {
  border: 1px solid rgb(255, 255, 255, 1);
  background-color: rgb(255, 255, 255, 0.2);
}
