.nav-container {
  top: 0;
  color: white;
  position: absolute;
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  z-index: 999;
  background-color: rgb(223, 198, 198, 0.15);
  border-bottom: 1px solid rgb(255, 255, 255, 0.4);
  /* background-color: rgb(65, 65, 65); */
}

.myst-logo {
  margin-right: 100px;
}

.link-container {
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.logo-icon {
  margin-right: 5px;
  font-size: 28px;
  transition: 0.6s;
}

.logo-icon:hover {
  color: rgb(102, 192, 244);
}

.logo-text {
  font-weight: 1000;
  font-size: 28px;
  text-transform: uppercase;
}

.individual-links {
  color: white;
  margin: 0px 15px;
  padding: 10px 25px;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  transition: 0.8s;
  border-radius: 12px;
}

.individual-links:hover {
  background-color: rgb(255, 255, 255, 0.4);
  border-radius: 12px;
}

.main-search-bar-container {
  min-width: 20%;
}
.main-search-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgb(23, 26, 33, 0.8);
  border: none;
  padding: 7px;
  font-size: 14px;
  width: 100%;
  border: 1px solid rgb(255, 255, 255, 0.5);
}

.main-search-bar input {
  width: 100%;
}

.main-search-bar input,
button {
  color: white;
  background-color: transparent;
  border: transparent;
}

.search-box-container {
  position: absolute;
  font-size: 14px;
  background-color: rgb(85, 85, 85);
  width: 395px;
  top: 75px;
}

.game-search-link {
  padding: 5px;
  cursor: pointer;
  transition: 0.2s all;
}

.game-search-link:hover {
  background-color: rgb(255, 255, 255, 0.5);
}

::placeholder,
:focus {
  color: white;
  outline: none;
}

.welcome-back-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 14px;
  text-transform: uppercase;
  margin-right: 20px;
}

.welcome-back-cart {
  position: relative;
  display: flex;
  flex-direction: row;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  margin-right: 18px;
}

.welcome-back-cart:has(.cart-size-show)::after {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  content: attr(data-cart-size);
  height: 18px;
  width: 18px;
  left: 14px;
  top: -3px;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  background-color: rgb(255, 0, 0, 0.8);
}

.welcome-back {
  margin-left: 20px;
}

.welcome-back-username {
  color: #66c0f4;
}

.welcome-back-avatar {
  position: relative;
  margin-left: 20px;
  width: 35px;
  height: 35px;
}

.welcome-back-avatar img {
  object-fit: cover;
  border-radius: 10px;
}

.welcome-back-dropdown {
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 30px;
  position: absolute;
  top: 120%;
  left: 50%;
  transform: translateX(-50%) translateY(-10px);
  opacity: 0;
  visibility: hidden;
  border: 1px solid rgb(255, 255, 255, 0.5);
  background-color: rgb(0, 0, 0, 0.8);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    transform 0.2s ease-in-out;
  z-index: 999;
}

.welcome-back-avatar:hover .welcome-back-dropdown {
  opacity: 999;
  visibility: visible;
  transform: translateX(-50%) translateY(0);
}

.nav-buttons-container {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.nav-login {
  margin-right: 10px;
  text-transform: uppercase;
  background-color: rgb(41, 69, 92, 0.8);
  padding: 8px 25px;
  border-radius: 12px;
  cursor: pointer;
  transition: 0.8s;
}

.nav-register {
  background-color: rgb(102, 192, 244, 0.8);
  text-transform: uppercase;
  padding: 8px 25px;
  border-radius: 12px;
  cursor: pointer;
  transition: 0.8s;
}

.nav-login:hover {
  background-color: rgb(41, 69, 92);
}

.nav-register:hover {
  background-color: rgb(102, 192, 244);
}

.profile-dropdown {
  position: absolute;
}

.hidden {
  display: none;
}

.circle {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 10px;
  background: #fff;
  border: 3px solid #000;
  color: #000;
  text-align: center;
  font: 32px Arial, sans-serif;
}

.spacer {
  content: " ";
  margin-right: 292.5px;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 10px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: all 0.3s ease;
}

.dropdown.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
