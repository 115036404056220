.game-selection-parent::before {
  content: "";
  /* border: 1px solid red; */
  position: absolute;
  /* top: 0;
  left: 0; */
  width: 100%;
  height: 20%;
  background-image: linear-gradient(#0f131b, transparent);
  opacity: 1;
  z-index: 1;
}

.game-selection-parent {
  /* margin: 80px 0px; */
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
}

.game-selection-container {
  z-index: 999;
  display: flex;
  flex-direction: column;
  width: 1350px;
  max-width: 1350px;
  /* border: 1px solid red; */
  /* align-items: center; */
}

.game-selection-header {
  color: white;
  font-size: 44px;
  font-weight: bold;
  margin-left: 4px;
}

.category-tab-container {
  /* border: 1px solid red; */
  width: 100%;
}

.category-tab-btn {
  font-size: 14px;
  cursor: pointer;
  margin: 5px;
  padding: 8px 30px 8px 30px;
  border: 1px solid white;
  border-radius: 4px;
  transition: all 0.4s;
}

.category-tab-btn:hover {
  background-color: white;
  color: black;
}

.active-category-tab {
  border: 1px solid rgb(102, 192, 244, 1);
  color: black;
  background-color: rgb(102, 192, 244, 1);
}

.active-category-tab:hover {
  background-color: rgb(102, 192, 244, 1);
}

.tab-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  /* gap: 10px; */
}

.splash-game-container {
  margin: 8px 0px 0px 5px;
  /* border: 1px solid red; */
  /* padding: 4px; */
  color: white;
  display: flex;
  flex-direction: row;
  transition: all 0.3s ease-in-out;
}

.splash-game-container:hover {
  background-color: rgb(36, 40, 49, 0.3);
  transform: scale(1.01);
  /* border: 1px solid rgb(120, 146, 185, 0.8); */
}

.splash-game-banner {
  margin-right: 20px;
}

.splash-game-banner img {
  border: 1px solid rgb(68, 89, 121, 0.8);
  padding: 5px;
  /* min-width: 460px;
  min-height: 215px; */
}

.splash-game-info-parent {
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
  border: 1px solid #445979;
  background: linear-gradient(
    340deg,
    transparent 20%,
    rgba(36, 40, 49, 1) 100%
  );
  width: 100%;
}

.splash-game-info-container {
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-width: 320px;
}

.splash-game-title {
  line-height: 1;
  padding: 0px 10px;
  font-size: 28px;
}

.splash-game-categories-container {
  width: 100%;
  padding: 0px 10px;
  display: flex;
  flex-direction: row;
}

.splash-game-category {
  font-size: 12px;
  padding: 3px 5px 3px 8px;
  background-color: rgb(102, 192, 244, 0.8);
  margin-right: 5px;
  margin-top: 5px;
}

.splash-reviews-container {
  display: flex;
  flex-direction: row;
  padding: 0px 10px;
  margin-top: 5px;
  font-size: 12px;
  text-transform: uppercase;
}

.splash-price {
  font-size: 20px;
  padding: 0px 10px;
}

.splash-screenshots-parent {
  /* border: 1px solid blue; */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.splash-screenshot {
  filter: brightness(80%);
  transition: 0.4s;
  width: 210px;
}

.splash-screenshot:hover {
  filter: brightness(100%);
}

.splash-pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.pagination-container {
  user-select: none;
  background: linear-gradient(
    0deg,
    rgba(236, 236, 236, 0.02) 0%,
    transparent 100%
  );
  border-top: 1px solid rgb(68, 89, 121, 0.5);
  border-bottom: 1px solid rgb(68, 89, 121, 0.5);
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 5px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
}

.pagination-btn {
  margin: 10px;
  cursor: pointer;
  font-size: 12px;
  /* border: 1px solid red; */
}

.pagination-btn:hover {
  cursor: pointer;
}

.pagination-btn.active {
  color: #66c0f4;
}
