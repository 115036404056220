.game-detail-parent {
  display: flex;
  margin-top: 150px;
  /* flex-direction: row; */
  /* align-items: center; */
  justify-content: center;
}

.game-detail-container {
  display: flex;
  flex-direction: row;
  padding: 5px 5px 0px 5px;
  border: 1px solid rgb(68, 89, 121, 0.5);
}

.game-detail-screenshots {
  /* opacity: 0.6; */
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
}

.game-detail-screenshots:after {
  content: "";
  display: table;
  clear: both;
}

.screenshot-thumbnail {
  /* width: 100%; */
  margin: 0px 3px 0px 3px;
}

.screenshot-thumbnail img {
  opacity: 0.5;
  /* width: 160px; */
  cursor: pointer;
  transition: 0.4s;
  outline: 1px solid rgb(68, 89, 121, 0.4);
}

.screenshot-thumbnail img:hover {
  opacity: 1;
}
.game-detail-slideshow-container {
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  width: 860px;
  padding: 5px;
  border: 1px solid;
  border-image: linear-gradient(to bottom, #445979, transparent) 1;
}

.active-game-detail {
  cursor: pointer;
  width: 100%;
}

img.active-game-detail-2 {
  outline: 1px solid rgb(255, 255, 255, 0.8);
  box-sizing: border-box;
  opacity: 1;
}

.game-detail-info-container {
  margin-bottom: 5px;
  color: white;
  background: linear-gradient(
    145deg,
    transparent 20%,
    rgba(36, 40, 49, 1) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 460px;
  border: 1px solid;
  border-image: linear-gradient(to top, #445979, transparent) 1;
}

.game-detail-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 10px;
}

.game-detail-title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.game-detail-title {
  font-size: 26px;
  margin-bottom: 5px;
  font-weight: 1000;
}

.game-detail-price {
  font-size: 18px;
}

.game-detail-description {
  font-size: 14px;
  text-align: justify;
}

.game-detail-image {
  /* cursor: pointer; */
  /* width: 860px; */
  /* box-sizing: border-box; */
}

.game-detail-main-img-container {
  box-sizing: border-box;
  /* border-bottom: 1px solid rgb(255, 255, 255, 0.1); */
  /* width: 460px; */
}

.game-detail-mid-info {
  font-size: 12px;
  color: rgb(150, 150, 150);
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
}

.game-detail-value {
  margin-left: 39px;
}

.game-detail-mixed-score {
  color: orange;
}

.game-detail-negative-score {
  color: red;
}

.game-detail-positive-score {
  color: rgb(102, 192, 244);
}

.game-detail-review-length {
  color: rgb(150, 150, 150);
}

.game-detail-value2 {
  margin-left: 33px;
  color: rgb(219, 219, 219);
}

.game-detail-value3 {
  margin-left: 47px;
  color: rgb(102, 192, 244);
}

.game-detail-value4 {
  margin-left: 51px;
  color: rgb(102, 192, 244);
}

.game-detail-categories-container {
  display: flex;
  gap: 5px;
  flex-direction: row;
  font-size: 10px;
  text-transform: uppercase;
  cursor: default;
}

.game-detail-category {
  background-color: rgb(255, 255, 255, 0.2);
  border: 1px solid rgb(255, 255, 255, 0.5);
  padding: 5px;
  border-radius: 5px;
}

.btn-container {
  display: flex;
  flex-direction: column;
}

.add-to-cart-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.wishlist-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-to-purchase {
  font-size: 12px;
  color: white;
  text-transform: uppercase;
  padding: 8px;
  border: 1px solid white;
  /* background-color: white; */
  text-align: center;
  width: 424px;
  transition: 0.4s;
}

.login-to-purchase:hover {
  background-color: white;
  color: black;
}

.add-to-cart-btn {
  cursor: pointer;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  /* background-color: rgba(93, 153, 187, 0.5); */
  background-image: linear-gradient(
    to right,
    rgb(79, 107, 153, 0.5) 0%,
    rgb(163, 192, 206, 0.5) 51%,
    rgb(79, 107, 153, 0.5) 100%
  );
  background-size: 200% auto;
  padding: 8px;
  border: 1px solid rgb(235, 235, 235, 0.5);
  transition: all 0.4s;
}

.wishlist-btn {
  cursor: pointer;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  /* background-color: rgba(93, 153, 187, 0.5); */
  padding: 8px;
  border: 1px solid rgb(235, 235, 235, 0.5);
  transition: all 0.4s;
}

.add-to-cart-btn:hover {
  background-position: right center;
}

.modal {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.modal-image-wrapper {
  position: relative;
  width: 1200px;
}

.modal-image-screenshot {
  padding: 10px;
  border: 1px solid rgb(102, 192, 244, 0.3);
}

.close-modal-container {
  background-color: rgb(102, 192, 244, 0.9);
  border-radius: 50%;
  padding: 6px;
  position: fixed;
  left: 340px;
  top: 118px;
  display: flex;
  font-size: 25px;
  transition: 0.4s;
}

.close-modal-container:hover {
  background-color: rgb(102, 192, 244, 1);
}

.close-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
