.entire-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: #212327; */
  width: 100%;
  height: 100vh;
}

.login-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  background-color: rgb(255, 255, 255);
  height: 600px;
  width: 1000px;
  border: 1px solid white;
}

.left-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 70%;
  background: linear-gradient(
    rgba(159, 175, 179, 1) 0%,
    rgba(84, 103, 117, 1) 100%
  );
}

.myst-login-logo {
  color: white;
}

.login-form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-main-header {
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: bold;
}

.login-errors-container {
  margin-left: -40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style-type: none;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: red;
  /* background-color: red; */
}

.login-errors-container > ul {
  list-style-type: none;
}

.login-button-container {
  text-align: center;
  margin-top: 10px;
  padding: 10px;
  background-color: rgba(84, 103, 117, 1);
  cursor: pointer;
}

.login-button-container button {
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.input-container {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
}

.input-container input {
  width: 96.5%;
  font-weight: normal;
  /* width: 250px; */
  padding: 5px;
  margin-bottom: 10px;
  color: rgba(84, 103, 117, 1);
}

.go-back {
  margin-top: 20px;
  cursor: pointer;
}
