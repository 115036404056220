/* TODO Add site wide styles */
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300;400&family=Noto+Sans:wght@400;900&family=Roboto:wght@300;400&display=swap");

* {
  font-family: "Kanit", sans-serif;
}

a:visited {
  color: white;
}

a:link {
  color: white;
}

a:active {
  color: white;
}

body {
  height: 100%;
  background-image: linear-gradient(transparent, rgb(23, 26, 33));
  /* background-image: linear-gradient(transparent, rgb(31, 35, 44)); */
  background-color: #050914;
  background-attachment: fixed;
  overflow: overlay;
  overflow-x: hidden;
}

.app-container {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(82, 91, 110, 0.7);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(82, 91, 110, 0.8);
  border-radius: 10px;
}
