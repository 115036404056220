@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@300;400&family=Noto+Sans:wght@400;900&family=Roboto:wght@300;400&display=swap);
#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
  position: absolute;
  background-color:white;
}
.form-error-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: red;
  /* width: 260px; */
}

.form-error {
  /* text-transform: none; */
  font-size: 12px;
}

.form-container {
  width: 460px;
}

.entire-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: #212327; */
  width: 100%;
  height: 100vh;
}

.login-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  background-color: rgb(255, 255, 255);
  height: 600px;
  width: 1000px;
  border: 1px solid white;
}

.left-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 70%;
  background: linear-gradient(
    rgba(159, 175, 179, 1) 0%,
    rgba(84, 103, 117, 1) 100%
  );
}

.myst-login-logo {
  color: white;
}

.login-form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-main-header {
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: bold;
}

.login-errors-container {
  margin-left: -40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style-type: none;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: red;
  /* background-color: red; */
}

.login-errors-container > ul {
  list-style-type: none;
}

.login-button-container {
  text-align: center;
  margin-top: 10px;
  padding: 10px;
  background-color: rgba(84, 103, 117, 1);
  cursor: pointer;
}

.login-button-container button {
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.input-container {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
}

.input-container input {
  width: 96.5%;
  font-weight: normal;
  /* width: 250px; */
  padding: 5px;
  margin-bottom: 10px;
  color: rgba(84, 103, 117, 1);
}

.go-back {
  margin-top: 20px;
  cursor: pointer;
}



.nav-container {
  top: 0;
  color: white;
  position: absolute;
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  z-index: 999;
  background-color: rgb(223, 198, 198, 0.15);
  border-bottom: 1px solid rgb(255, 255, 255, 0.4);
  /* background-color: rgb(65, 65, 65); */
}

.myst-logo {
  margin-right: 100px;
}

.link-container {
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.logo-icon {
  margin-right: 5px;
  font-size: 28px;
  transition: 0.6s;
}

.logo-icon:hover {
  color: rgb(102, 192, 244);
}

.logo-text {
  font-weight: 1000;
  font-size: 28px;
  text-transform: uppercase;
}

.individual-links {
  color: white;
  margin: 0px 15px;
  padding: 10px 25px;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  transition: 0.8s;
  border-radius: 12px;
}

.individual-links:hover {
  background-color: rgb(255, 255, 255, 0.4);
  border-radius: 12px;
}

.main-search-bar-container {
  min-width: 20%;
}
.main-search-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgb(23, 26, 33, 0.8);
  border: none;
  padding: 7px;
  font-size: 14px;
  width: 100%;
  border: 1px solid rgb(255, 255, 255, 0.5);
}

.main-search-bar input {
  width: 100%;
}

.main-search-bar input,
button {
  color: white;
  background-color: transparent;
  border: transparent;
}

.search-box-container {
  position: absolute;
  font-size: 14px;
  background-color: rgb(85, 85, 85);
  width: 395px;
  top: 75px;
}

.game-search-link {
  padding: 5px;
  cursor: pointer;
  transition: 0.2s all;
}

.game-search-link:hover {
  background-color: rgb(255, 255, 255, 0.5);
}

::-webkit-input-placeholder {
  color: white;
  outline: none;
}

::placeholder,
:focus {
  color: white;
  outline: none;
}

.welcome-back-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 14px;
  text-transform: uppercase;
  margin-right: 20px;
}

.welcome-back-cart {
  position: relative;
  display: flex;
  flex-direction: row;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  margin-right: 18px;
}

.welcome-back-cart:has(.cart-size-show)::after {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  content: attr(data-cart-size);
  height: 18px;
  width: 18px;
  left: 14px;
  top: -3px;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  background-color: rgb(255, 0, 0, 0.8);
}

.welcome-back {
  margin-left: 20px;
}

.welcome-back-username {
  color: #66c0f4;
}

.welcome-back-avatar {
  position: relative;
  margin-left: 20px;
  width: 35px;
  height: 35px;
}

.welcome-back-avatar img {
  object-fit: cover;
  border-radius: 10px;
}

.welcome-back-dropdown {
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 30px;
  position: absolute;
  top: 120%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-10px);
          transform: translateX(-50%) translateY(-10px);
  opacity: 0;
  visibility: hidden;
  border: 1px solid rgb(255, 255, 255, 0.5);
  background-color: rgb(0, 0, 0, 0.8);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -webkit-transform 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    transform 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  z-index: 999;
}

.welcome-back-avatar:hover .welcome-back-dropdown {
  opacity: 999;
  visibility: visible;
  -webkit-transform: translateX(-50%) translateY(0);
          transform: translateX(-50%) translateY(0);
}

.nav-buttons-container {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.nav-login {
  margin-right: 10px;
  text-transform: uppercase;
  background-color: rgb(41, 69, 92, 0.8);
  padding: 8px 25px;
  border-radius: 12px;
  cursor: pointer;
  transition: 0.8s;
}

.nav-register {
  background-color: rgb(102, 192, 244, 0.8);
  text-transform: uppercase;
  padding: 8px 25px;
  border-radius: 12px;
  cursor: pointer;
  transition: 0.8s;
}

.nav-login:hover {
  background-color: rgb(41, 69, 92);
}

.nav-register:hover {
  background-color: rgb(102, 192, 244);
}

.profile-dropdown {
  position: absolute;
}

.hidden {
  display: none;
}

.circle {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 10px;
  background: #fff;
  border: 3px solid #000;
  color: #000;
  text-align: center;
  font: 32px Arial, sans-serif;
}

.spacer {
  content: " ";
  margin-right: 292.5px;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 10px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  transition: all 0.3s ease;
}

.dropdown.active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.footer-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.footer-container {
  /* border-top: 1px solid rgb(68, 89, 121, 0.8); */
  height: 80px;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  /* font-size: 12px; */
  /* background-color: black; */
}

.footer-developer-info-container {
  cursor: default;
  color: white;
  font-size: 12px;
  height: 100%;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255, 0.1);
}

.footer-view-project-container {
  color: white;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(109, 205, 154, 0.5);
  width: 50%;
  height: 100%;
  transition: 0.4s;
}

.footer-view-project-container:hover {
  background-color: rgb(109, 205, 154, 1);
}

.footer-developer-info-container {
  display: flex;
  flex-direction: row;
  width: 50%;
  height: 100%;
}

.footer-github-container {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(61, 172, 221, 0.7);
  width: 10%;
  height: 100%;
  font-size: 30px;
  transition: 0.4s;
}

.footer-github-container:hover {
  background-color: rgb(61, 172, 221, 1);
}

.footer-linkedin-container {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(30, 131, 174, 0.5);
  width: 10%;
  height: 100%;
  font-size: 30px;
  transition: 0.4s;
}

.footer-linkedin-container:hover {
  background-color: rgb(30, 131, 174, 0.8);
}

.game-selection-parent::before {
  content: "";
  /* border: 1px solid red; */
  position: absolute;
  /* top: 0;
  left: 0; */
  width: 100%;
  height: 20%;
  background-image: linear-gradient(#0f131b, transparent);
  opacity: 1;
  z-index: 1;
}

.game-selection-parent {
  /* margin: 80px 0px; */
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
}

.game-selection-container {
  z-index: 999;
  display: flex;
  flex-direction: column;
  width: 1350px;
  max-width: 1350px;
  /* border: 1px solid red; */
  /* align-items: center; */
}

.game-selection-header {
  color: white;
  font-size: 44px;
  font-weight: bold;
  margin-left: 4px;
}

.category-tab-container {
  /* border: 1px solid red; */
  width: 100%;
}

.category-tab-btn {
  font-size: 14px;
  cursor: pointer;
  margin: 5px;
  padding: 8px 30px 8px 30px;
  border: 1px solid white;
  border-radius: 4px;
  transition: all 0.4s;
}

.category-tab-btn:hover {
  background-color: white;
  color: black;
}

.active-category-tab {
  border: 1px solid rgb(102, 192, 244, 1);
  color: black;
  background-color: rgb(102, 192, 244, 1);
}

.active-category-tab:hover {
  background-color: rgb(102, 192, 244, 1);
}

.tab-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  /* gap: 10px; */
}

.splash-game-container {
  margin: 8px 0px 0px 5px;
  /* border: 1px solid red; */
  /* padding: 4px; */
  color: white;
  display: flex;
  flex-direction: row;
  transition: all 0.3s ease-in-out;
}

.splash-game-container:hover {
  background-color: rgb(36, 40, 49, 0.3);
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  /* border: 1px solid rgb(120, 146, 185, 0.8); */
}

.splash-game-banner {
  margin-right: 20px;
}

.splash-game-banner img {
  border: 1px solid rgb(68, 89, 121, 0.8);
  padding: 5px;
  /* min-width: 460px;
  min-height: 215px; */
}

.splash-game-info-parent {
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
  border: 1px solid #445979;
  background: linear-gradient(
    340deg,
    transparent 20%,
    rgba(36, 40, 49, 1) 100%
  );
  width: 100%;
}

.splash-game-info-container {
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-width: 320px;
}

.splash-game-title {
  line-height: 1;
  padding: 0px 10px;
  font-size: 28px;
}

.splash-game-categories-container {
  width: 100%;
  padding: 0px 10px;
  display: flex;
  flex-direction: row;
}

.splash-game-category {
  font-size: 12px;
  padding: 3px 5px 3px 8px;
  background-color: rgb(102, 192, 244, 0.8);
  margin-right: 5px;
  margin-top: 5px;
}

.splash-reviews-container {
  display: flex;
  flex-direction: row;
  padding: 0px 10px;
  margin-top: 5px;
  font-size: 12px;
  text-transform: uppercase;
}

.splash-price {
  font-size: 20px;
  padding: 0px 10px;
}

.splash-screenshots-parent {
  /* border: 1px solid blue; */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.splash-screenshot {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
  transition: 0.4s;
  width: 210px;
}

.splash-screenshot:hover {
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}

.splash-pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.pagination-container {
  -webkit-user-select: none;
          user-select: none;
  background: linear-gradient(
    0deg,
    rgba(236, 236, 236, 0.02) 0%,
    transparent 100%
  );
  border-top: 1px solid rgb(68, 89, 121, 0.5);
  border-bottom: 1px solid rgb(68, 89, 121, 0.5);
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 5px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
}

.pagination-btn {
  margin: 10px;
  cursor: pointer;
  font-size: 12px;
  /* border: 1px solid red; */
}

.pagination-btn:hover {
  cursor: pointer;
}

.pagination-btn.active {
  color: #66c0f4;
}

/* Slideshow container */
.slideshow-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(transparent 0%, #0f131b 100%);
  opacity: 1;
  z-index: 1;
}

.slideshow-container {
  width: 100%;
  position: relative;
  margin: auto;
  overflow-x: hidden;
}

.slideshow-container img {
  -webkit-filter: brightness(60%);
          filter: brightness(60%);
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  -webkit-user-select: none;
          user-select: none;
  z-index: 999;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */
.textContainer {
  position: absolute;
  z-index: 999;
  top: 42%;
  left: 5%;
}

.game-info-container {
  display: flex;
  flex-direction: column;
  z-index: 999;
}

.game-title {
  color: white;
  font-size: 56px;
  font-weight: 800;
  /* padding: 8px 12px; */
  /* position: absolute; */
  /* top: 500px;
  left: -700px; */
  /* width: 100%; */
  /* text-align: center; */
}

.game-price {
  margin-left: 1px;
  font-size: 24px;
  color: white;
}

.game-button {
  border: 1px solid rgb(255, 255, 255, 0.5);
  background-color: rgb(27, 40, 56, 0.9);
  color: white;
  margin-top: 15px;
  padding: 15px 55px 15px 55px;
  cursor: pointer;
  transition: 0.8s;
  font-size: 18px;
}

.game-button:hover {
  background-color: white;
  color: black;
}

/* The dots/bullets/indicators */
.dots {
  /* position: absolute; */
  /* bottom: 0; */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: rgb(255, 255, 255, 0.3);
  border-radius: 50%;
  display: inline-block;
  flex-direction: row;
  transition: background-color 0.6s ease;
}

.dotactive,
.dot:hover {
  background-color: #ffffff;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
          animation-name: fade;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

.lrg-featured-banner-url {
  width: 100%;
  object-fit: cover;
  height: 100vh;
}

.bottom-container {
  position: absolute;
  width: 100%;
  bottom: 10%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  z-index: 999;
}

.featured-header {
  margin-top: 90px;
  display: flex;
  color: white;
  font-weight: bold;
  /* justify-content: center; */
}

.thumbnails {
  /* position: absolute; */
  /* width: 100%; */
  /* bottom: 10%; */
  /* background-color: transparent; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 15px 0px;
}

.thumbnail {
  height: 120px;
  width: 280px;
  margin: 0px 5px;
  cursor: pointer;
}

.featured-banner-url {
  object-fit: cover;
  height: 100%;
  width: 100%;
  -webkit-filter: brightness(50%);
          filter: brightness(50%);
  transition: 0.4s;
}

.active .featured-banner-url {
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
  box-sizing: border-box;
  outline: 3px solid rgb(255, 255, 255, 0.8);
}

.featured-banner-url:hover {
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}


.game-detail-parent {
  display: flex;
  margin-top: 150px;
  /* flex-direction: row; */
  /* align-items: center; */
  justify-content: center;
}

.game-detail-container {
  display: flex;
  flex-direction: row;
  padding: 5px 5px 0px 5px;
  border: 1px solid rgb(68, 89, 121, 0.5);
}

.game-detail-screenshots {
  /* opacity: 0.6; */
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
}

.game-detail-screenshots:after {
  content: "";
  display: table;
  clear: both;
}

.screenshot-thumbnail {
  /* width: 100%; */
  margin: 0px 3px 0px 3px;
}

.screenshot-thumbnail img {
  opacity: 0.5;
  /* width: 160px; */
  cursor: pointer;
  transition: 0.4s;
  outline: 1px solid rgb(68, 89, 121, 0.4);
}

.screenshot-thumbnail img:hover {
  opacity: 1;
}
.game-detail-slideshow-container {
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  width: 860px;
  padding: 5px;
  border: 1px solid;
  border-image: linear-gradient(to bottom, #445979, transparent) 1;
}

.active-game-detail {
  cursor: pointer;
  width: 100%;
}

img.active-game-detail-2 {
  outline: 1px solid rgb(255, 255, 255, 0.8);
  box-sizing: border-box;
  opacity: 1;
}

.game-detail-info-container {
  margin-bottom: 5px;
  color: white;
  background: linear-gradient(
    145deg,
    transparent 20%,
    rgba(36, 40, 49, 1) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 460px;
  border: 1px solid;
  border-image: linear-gradient(to top, #445979, transparent) 1;
}

.game-detail-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 10px;
}

.game-detail-title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.game-detail-title {
  font-size: 26px;
  margin-bottom: 5px;
  font-weight: 1000;
}

.game-detail-price {
  font-size: 18px;
}

.game-detail-description {
  font-size: 14px;
  text-align: justify;
}

.game-detail-image {
  /* cursor: pointer; */
  /* width: 860px; */
  /* box-sizing: border-box; */
}

.game-detail-main-img-container {
  box-sizing: border-box;
  /* border-bottom: 1px solid rgb(255, 255, 255, 0.1); */
  /* width: 460px; */
}

.game-detail-mid-info {
  font-size: 12px;
  color: rgb(150, 150, 150);
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
}

.game-detail-value {
  margin-left: 39px;
}

.game-detail-mixed-score {
  color: orange;
}

.game-detail-negative-score {
  color: red;
}

.game-detail-positive-score {
  color: rgb(102, 192, 244);
}

.game-detail-review-length {
  color: rgb(150, 150, 150);
}

.game-detail-value2 {
  margin-left: 33px;
  color: rgb(219, 219, 219);
}

.game-detail-value3 {
  margin-left: 47px;
  color: rgb(102, 192, 244);
}

.game-detail-value4 {
  margin-left: 51px;
  color: rgb(102, 192, 244);
}

.game-detail-categories-container {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  flex-direction: row;
  font-size: 10px;
  text-transform: uppercase;
  cursor: default;
}

.game-detail-category {
  background-color: rgb(255, 255, 255, 0.2);
  border: 1px solid rgb(255, 255, 255, 0.5);
  padding: 5px;
  border-radius: 5px;
}

.btn-container {
  display: flex;
  flex-direction: column;
}

.add-to-cart-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.wishlist-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-to-purchase {
  font-size: 12px;
  color: white;
  text-transform: uppercase;
  padding: 8px;
  border: 1px solid white;
  /* background-color: white; */
  text-align: center;
  width: 424px;
  transition: 0.4s;
}

.login-to-purchase:hover {
  background-color: white;
  color: black;
}

.add-to-cart-btn {
  cursor: pointer;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  /* background-color: rgba(93, 153, 187, 0.5); */
  background-image: linear-gradient(
    to right,
    rgb(79, 107, 153, 0.5) 0%,
    rgb(163, 192, 206, 0.5) 51%,
    rgb(79, 107, 153, 0.5) 100%
  );
  background-size: 200% auto;
  padding: 8px;
  border: 1px solid rgb(235, 235, 235, 0.5);
  transition: all 0.4s;
}

.wishlist-btn {
  cursor: pointer;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  /* background-color: rgba(93, 153, 187, 0.5); */
  padding: 8px;
  border: 1px solid rgb(235, 235, 235, 0.5);
  transition: all 0.4s;
}

.add-to-cart-btn:hover {
  background-position: right center;
}

.modal {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.modal-image-wrapper {
  position: relative;
  width: 1200px;
}

.modal-image-screenshot {
  padding: 10px;
  border: 1px solid rgb(102, 192, 244, 0.3);
}

.close-modal-container {
  background-color: rgb(102, 192, 244, 0.9);
  border-radius: 50%;
  padding: 6px;
  position: fixed;
  left: 340px;
  top: 118px;
  display: flex;
  font-size: 25px;
  transition: 0.4s;
}

.close-modal-container:hover {
  background-color: rgb(102, 192, 244, 1);
}

.close-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.review-parent {
  color: white;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: center;
  /* border: 1px solid white; */
}

.review-container-left-right {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1350px;
  /* border: 1px solid red; */
}

.review-container {
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  width: 868px;
  margin-right: 10px;
  grid-gap: 5px;
  gap: 5px;
}

.review-header {
  text-align: left;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 1000;
  /* text-shadow: 4px 1px 0px rgb(102, 192, 244, 0.4); */
  line-height: 35px;
  /* border-bottom: 1px solid rgb(68, 89, 121, 0.5); */
  cursor: default;
  -webkit-user-select: none;
          user-select: none;
}

.individual-review-container {
  display: flex;
  flex-direction: row;
  /* width: 865px; */
  margin-left: 1px;
  margin-bottom: 20px;
  /* padding: 3px; */
  /* border: 1px solid rgb(68, 89, 121, 0.5); */
  /* border-top: 1px solid rgb(68, 89, 121, 0.5); */
  /* background-color: rgba(26, 38, 51, 0.6); */
  background-color: linear-gradient(
    0deg,
    transparent 0%,
    rgba(36, 40, 49, 1) 100%
  );
}

.individual-review-container2 {
  display: flex;
  flex-direction: row;
  margin-left: 1px;
  margin-bottom: 20px;
  /* background-color: rgb(68, 89, 121, 0.2); */
  background: linear-gradient(
    145deg,
    transparent 20%,
    rgba(68, 89, 121, 0.2) 100%
  );
  /* border: 1px solid rgba(45, 230, 255, 0.3); */
  /* box-shadow: 0px 0px 0px 1px rgba(45, 230, 255, 0.3); */
  padding: 10px;
  border: 1px solid;
  border-image-slice: 1;
  border-image: linear-gradient(
      var(--angle),
      rgb(45, 230, 255, 0.8),
      rgb(45, 125, 255, 0.5)
    )
    1;
  -webkit-animation: 5s rotate linear infinite;
          animation: 5s rotate linear infinite;
  --angle: 0deg;
}

@-webkit-keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

.author-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  padding: 0px 5px 3px 0px;
  /* border-right: 1px solid rgb(68, 89, 121, 0.5); */
  /* width: 100%; */
}

.author-avatar img {
  -webkit-user-select: none;
          user-select: none;
  height: 68px;
  width: 78px;
  object-fit: cover;
  /* outline: 1px solid rgb(68, 89, 121, 0.5); */
  border: 1px solid #445979;
  /* border-image: linear-gradient(to top, #445979, transparent) 1; */
}

.review-text-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* margin-left: 5px; */
  /* padding: 3px; */
  /* padding-bottom: 20px; */
  /* background-color: rgb(27, 40, 56, 0.8); */
  /* border: 1px solid white; */
}

.review-text-top-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* padding: 3px; */
  /* border: 1px solid rgb(68, 89, 121, 0.5); */
}

.review-top-info-container {
  padding: 5px 4px 5px 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: linear-gradient(
    -145deg,
    transparent 20%,
    rgba(122, 195, 238, 0.1) 100%
  );
  border-top: 1px solid rgb(68, 89, 121, 0.5);
  border-bottom: 1px solid rgb(68, 89, 121, 0.5);
  font-size: 12px;
}

.review-posted {
  color: rgb(150, 150, 150);
  font-size: 10px;
  text-transform: uppercase;
}

.recommended-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: linear-gradient(
    145deg,
    transparent 0%,
    rgba(9, 11, 15, 0.5) 100%
  );
  border-bottom: 1px solid rgb(68, 89, 121, 0.5);
}

.recommended,
.not-recommended {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.thumbs-up,
.thumbs-down {
  font-size: 24px;
  padding: 2px 8px;
  /* border: 1px solid pink; */
  margin-right: 10px;
}

.thumbs-up {
  background: linear-gradient(
    0deg,
    rgb(12, 53, 80, 0.3) 0%,
    rgb(63, 103, 129, 0.6) 100%
  );
}

.thumbs-down {
  background: linear-gradient(0deg, rgb(77, 22, 28) 0%, rgb(119, 72, 78) 100%);
}

.thumb-icon {
  opacity: 0.6;
  text-shadow: 2px 3px 0px rgba(0, 0, 0, 0.6);
}

.key-icon {
  margin-right: 10px;
}

.review-text {
  text-align: justify;
  font-size: 12px;
  padding: 5px 0px;
}

.review-user-actions {
  /* border-top: 1px solid rgb(255, 255, 255, 0.2); */
  /* background-color: black; */
  /* border-top: 1px solid;
  border-image: linear-gradient(to right, transparent, rgb(255, 255, 255, 0.1))
    1; */
  font-size: 11px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.review-edit-btns-container {
  margin-top: 5px;
}

.review-user-actions-edit {
  margin-top: 15px;
  border-top: 1px solid rgb(255, 255, 255, 0.1);
  font-size: 11px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.edit-review,
.delete-review,
.cancel-review,
.save-review {
  margin: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  transition: 0.4s;
}

.edit-review,
.cancel-review {
  cursor: pointer;
  background-color: rgb(255, 255, 255, 0.4);
}

.edit-review:hover,
.cancel-review:hover {
  background-color: rgb(255, 255, 255);
  color: black;
}

.delete-review {
  background-color: rgb(255, 0, 0, 0.4);
  margin-left: 5px;
}

.delete-review:hover {
  background-color: rgb(255, 0, 0);
}

.save-review {
  cursor: pointer;
  background-color: rgb(62, 110, 139);
}

.save-review:hover {
  background-color: rgb(37, 145, 212);
}

.edit-review,
.delete-review {
  cursor: pointer;
}

.review-right-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  position: sticky;
  position: -webkit-sticky; /* for browser compatibility */
  top: 35%;
  width: 465px;
  /* height: 300px; */
  height: 100%;
  margin: 40px 0px;
  border: 1px solid rgb(68, 89, 121, 0.5);
  background: linear-gradient(-45deg, transparent 0%, rgba(36, 40, 49, 1) 100%);
  text-align: justify;
}

.review-create-header {
  font-size: 15px;
  color: rgb(255, 255, 255, 0.9);
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.9);
  padding: 5px 10px;
  background: linear-gradient(
    90deg,
    rgba(64, 100, 100, 0.8) 0%,
    rgb(20, 41, 41, 0.3) 100%
  );
}

.review-create-top-container {
  /* margin: 5px 0px; */
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.review-create-user-avatar {
  margin-right: 10px;
}

.review-create-user-avatar img {
  border: 1px solid #445979;
  height: 60px;
  width: 70px;
  object-fit: cover;
}

.review-create-subtext-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.review-create-subtext {
  /* padding: 10px 10px 0px 10px; */
  color: rgb(102, 192, 244);
  font-size: 14px;
}

.review-create-subtext2 {
  /* padding: 10px; */
  font-size: 12px;
}

.review-create-input-container,
.review-edit-input-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.review-create-input {
  /* border: 1px solid red; */
  /* width: 437px; */
  padding: 10px 16px 10px 10px;
  display: flex;
  flex-direction: column;
}

.review-edit-input {
  display: flex;
  flex-direction: column;
  margin: 5px 0px 10px 0px;
  width: 100%;
}

.review-create-input > textarea {
  border: 1px solid rgb(68, 89, 121, 0.8);
  background-color: rgb(0, 0, 0, 0.15);
  color: white;
  font-size: 13px;
  text-align: justify;
  resize: none;
  /* background-color: transparent; */
  height: 150px;
  width: 423px;
  padding: 10px;
}

.review-edit-input > textarea {
  border: 1px solid rgb(68, 89, 121, 0.8);
  background-color: rgb(0, 0, 0, 0.25);
  color: white;
  font-size: 12px;
  text-align: justify;
  resize: none;
  /* background-color: transparent; */
  height: 100px;
  /* width: 423px; */
  padding: 10px;
}

.do-you-rec {
  border: none;
  /* height: 100%; */
}

.do-you-rec-edit {
  border: none;
  padding: 0;
}

.do-you-rec-1 {
  font-size: 14px;
}

.do-you-rec-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.do-you-rec-radio-container input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.do-you-rec-radio-container label {
  background-color: rgb(255, 255, 255, 0.1);
  color: white;
  font-size: 12px;
  text-align: center;
  padding: 5px 16px;
  /* border: 1px solid rgba(255, 255, 255, 0.2); */
  /* box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1); */
  /* transition: all 0.2s ease-in-out; */
}

.do-you-rec-radio-container label:hover {
  cursor: pointer;
}

.do-you-rec-radio-container input:checked + label:first-of-type {
  outline: 1px solid rgb(255, 255, 255, 0.05);
  background: linear-gradient(
    0deg,
    rgb(12, 53, 80, 0.3) 10%,
    rgb(63, 103, 129, 0.8) 100%
  );
  box-shadow: none;
}

.do-you-rec-radio-container input:checked + label:last-of-type {
  outline: 1px solid rgb(255, 255, 255, 0.05);
  background: linear-gradient(0deg, rgb(77, 22, 28) 10%, rgb(119, 72, 78) 100%);
  box-shadow: none;
}

.do-you-rec-radio-container label:first-of-type {
  margin-right: 3px;
  border-radius: 4px;
}

.do-you-rec-radio-container label:last-of-type {
  border-radius: 4px;
}

.post-review-container button {
  cursor: pointer;
  font-size: 14px;
  background-color: white;
  color: black;
  padding: 8px 12px;
  border-radius: 5px;
}

.purchase-me-container {
  -webkit-user-select: none;
          user-select: none;
  opacity: 0.5;
  font-size: 12px;
  text-transform: uppercase;
  cursor: default;
  position: relative;
  border: 1px solid rgb(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 465px;
  height: 38px;
}

.purchase-me-container.arrow-top,
.purchase-me-container.arrow-left {
  margin-top: 40px;
}

.purchase-me-container.arrow-top:after {
  content: " ";
  position: absolute;
  /* right: 30px; */
  top: -11px;
  border-top: none;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid rgb(255, 255, 255, 0.5);
}

.purchase-me-container.arrow-left:after {
  content: " ";
  position: absolute;
  left: -11px;
  /* top: 15px; */
  border-top: 10px solid transparent;
  border-right: 10px solid rgb(255, 255, 255, 0.5);
  border-left: none;
  border-bottom: 10px solid transparent;
}

.cart-parent {
  margin-top: 150px;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  color: white;
  justify-content: center;
  /* height: 100vh; */
  /* width: 100%; */
}

.cart-container {
  display: flex;
  height: 100%;
}

.cart-game-card-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* background-color: rgb(255, 255, 255, 0.1); */
  height: 100%;
  margin-right: 4px;
}

.cart-game-card-header {
  width: 600px;
  max-width: 600px;
  margin-bottom: 2px;
  text-align: left;
  text-transform: uppercase;
  font-size: 38px;
  font-weight: 1000;
  text-shadow: 4px 1px 0px rgb(102, 192, 244, 0.4);
  line-height: 45px;
  border-bottom: 1px solid rgb(255, 255, 255, 0.4);
  cursor: default;
  -webkit-user-select: none;
          user-select: none;
}

.cart-game-card p {
  text-shadow: 10px 10px 0px 0px rgba(255, 255, 255, 0.75);
}

.cart-game-card {
  margin: 3px 0px;
  height: 100%;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* justify-content: center; */
  width: 600px;
  max-height: 115px;
}

.cart-game-card-banner {
  border: 1px solid rgb(199, 213, 224, 0.1);
}

.cart-game-card-info {
  margin-left: 5px;
  border: 1px solid rgb(199, 213, 224, 0.2);
  background-color: rgb(27, 40, 56, 0.4);
  width: 100%;
  height: 95px;
}

.cart-game-card-info {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
}

.cart-game-title {
  font-size: 20px;
}

.cart-game-price {
  font-size: 14px;
  font-weight: normal;
}

.cart-game-delete {
  font-size: 12px;
}

.cart-delete-btn {
  /* background-color: black; */
  margin-left: -5px;
  color: rgb(159, 161, 163, 0.7);
  cursor: pointer;
}

.clear-cart-btn {
  width: 100%;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
}

.check-out-container {
  margin-top: 45px;
  border: 1px solid rgb(199, 213, 224, 0.2);
  background: linear-gradient(
    145deg,
    transparent 20%,
    rgba(36, 40, 49, 1) 100%
  );
  width: 360px;
  height: 100%;
  padding: 10px;
}

.cart-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
}

.cart-game-title2 {
  font-size: 16px;
}

.cart-game-price2 {
  font-size: 14px;
}

.cart-tax {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.tax {
  font-size: 14px;
}

.divider {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgb(255, 255, 255, 0.2);
}

.for-space-between {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cart-total {
  text-align: right;
  font-size: 24px;
}

.purchase-button {
  background-color: rgb(255, 255, 255);
  padding: 10px;
  color: black;
  cursor: pointer;
}

.entire-library-parent {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.entire-library-container {
  display: flex;
  flex-direction: row;
  color: white;
  height: 100%;
}
.library-sidebar-parent {
  height: 100%;
  margin-top: 98px;
  width: 320px;
  border-right: 1px solid rgb(255, 255, 255, 0.4);
  background-color: rgb(255, 255, 255, 0.05);
}

.library-sidebar-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  grid-gap: 1px;
  gap: 1px;
}

.my-games-header {
  font-size: 14px;
  padding: 5px;
  text-transform: uppercase;
}

.library-game-list-container {
  font-size: 12px;
  cursor: pointer;
  padding: 5px 5px 5px 20px;
  /* background-color: rgb(255, 255, 255, 0.1); */
  transition: 0.4s;
}

.library-game-list-container:hover {
  background-color: rgb(255, 255, 255, 0.2);
}

.library-parent {
  /* height: 100%; */
  width: 100%;
  margin-left: 20px;
  margin-top: 110px;
  display: flex;
  flex-direction: column;
}

.library-header {
  font-size: 46px;
  font-weight: bold;
  text-transform: uppercase;
}

.library-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  align-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.library-game-container {
  padding: 5px;
  border: 1px solid rgb(68, 89, 121, 0.5);
  display: flex;
  flex-direction: column;
  width: 380px;
  transition: all 0.3s ease-in-out;
  z-index: 999;
  background: linear-gradient(
    145deg,
    transparent 20%,
    rgba(36, 40, 49, 0.5) 100%
  );
  /* height: 265px; */
  /* margin: 5px; */
}

.library-game-container:hover {
  background-color: rgb(36, 40, 49, 0.5);
  /* background: linear-gradient(
    145deg,
    transparent 20%,
    rgba(36, 40, 49, 1) 100%
  ); */
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  border: 1px solid rgb(120, 146, 185, 0.8);
}

.library-banner {
}

.library-info-container {
  /* margin: 5px; */
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.library-game-title-container {
  /* padding: 5px; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  margin-bottom: 15px;
}

.library-game-title {
  font-size: 20px;
  /* font-weight: bold; */
}

.library-game-purchase {
  font-size: 12px;
  text-transform: uppercase;
  color: rgb(150, 150, 150);
}

.library-links-container {
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
}

.library-button {
  cursor: pointer;
  text-transform: uppercase;
  border: 1px solid rgb(255, 255, 255, 0.4);
  width: 100%;
  padding: 3px;
  background-color: rgb(255, 255, 255, 0.05);
  /* border-radius: 3px; */
  font-size: 12px;
  transition: 0.2s;
}

.library-button:hover {
  border: 1px solid rgb(255, 255, 255, 1);
  background-color: rgb(255, 255, 255, 0.2);
}

/* TODO Add site wide styles */

* {
  font-family: "Kanit", sans-serif;
}

a:visited {
  color: white;
}

a:link {
  color: white;
}

a:active {
  color: white;
}

body {
  height: 100%;
  background-image: linear-gradient(transparent, rgb(23, 26, 33));
  /* background-image: linear-gradient(transparent, rgb(31, 35, 44)); */
  background-color: #050914;
  background-attachment: fixed;
  overflow: overlay;
  overflow-x: hidden;
}

.app-container {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(82, 91, 110, 0.7);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(82, 91, 110, 0.8);
  border-radius: 10px;
}

/*
  1. Use a more-intuitive box-sizing model.
*/
/* *,
*::before,
*::after {
  box-sizing: border-box;
} */
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
/* body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
} */
/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
/*
  9. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

